import {useEffect,useState} from "react";
//import Loading from './Loading';
//import Axios from "axios";
import Grid from '@material-ui/core/Grid';
import {TextField,Box} from '@material-ui/core';
import { Container } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
 
export const ConvArea=()=>{
  useEffect(()=>{
         
    window.scrollTo(0, 0);
  },[]);

return  (  <div>
    
    <Grid
  container
  direction="column"
  justify="space-evenly"
  alignItems="center"
  spacing={3}
>
<Grid item xs={12}>
         
   </Grid>
<Grid item xs={12}>
        <Typography variant="h5" component="h4" className="text-center" color="primary" gutterBottom>
          AREA 
        </Typography>
   </Grid>
  <Grid item xs={12}>
  <Area />
  </Grid>
  <Grid item xs={12}>
   
  </Grid>
  <Grid item xs={12}>
  <SimpleCard/>
  </Grid>
  </Grid>
      
     
    

</div>

 );
}

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "& .MuiFormLabel-root":{
      color:"#ffd24d"
    },
     
    "& .MuiInputLabel-shrink":{
      
      transform: "translate(14px, -9px) scale(0.90)",
      transformOrigin: "top left",
      backgroundColor:"rgb(41, 38, 38)",
      paddingLeft:"1%",
      paddingRight:"1%",
    } 
     
  }

});
 
  
  export default function SimpleCard() {
     
    
  
    return (
      <Box m={3} >
      <Card style={{ border:"2px groove #ffd24d"}}>
        <CardContent>
          
        <Typography variant="h6"  className="text-center">
          UNITS OF AREA
          </Typography>
          <br></br>
          <Typography variant="body2" >
          1 acre = 4046.86 sq.m = 43560 sq.ft The area measurement (acre) in the Metric systems is measured in square meters (sq.m), one acre is equal to 4046.86 sq.m. 
          Whereas in the English system the area is measured in square feet (sq.ft) with one acre equal to 43560sq.ft.
          </Typography>
        </CardContent>
        
      </Card>
      </Box>
    );
  }
    
   


  const Area=()=>{
    const classes = useStyles();
    const [sqinch,setSqinch] = useState();
    const [sqmet,setSqmet] = useState();
    const [sqcent,setSqcent] = useState();
    const [sqyard,setSqyard] = useState();
    const [sqfeet,setSqfeet] = useState();
    const [hect,setHect] = useState();
    const [acre,setAcre] = useState();
    const [sqmile,setSqmile] = useState();



    return (
        <Container maxWidth="lg">
        <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={4}
      >
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          
            <TextField  className={classes.root} label="Sq Inch" size="small" variant="outlined" fullWidth 
            InputLabelProps={{  shrink: true,focused:true }}
            type="number"
            InputProps={{
              style: {
                  color: "#ffd24d",
                  borderColor: "#ffd24d"
              }
          }}
                 
               

                value={sqinch}
                onChange={(e) => {
                   
                        var val = parseFloat(e.target.value);
                        var val1 = parseFloat(e.target.value);
                        setSqinch(val1);
                        val=val1/1550.003
                        setSqmet(val);
                        val=val1*6.452
                        setSqcent(val);
                        val=val1/1296
                        setSqyard(val);
                        val=val1/144
                        setSqfeet(val);
                        val=val1/15500000
                        setHect(val);
                        val=val1/6273000
                        setAcre(val)
                        val=val1/4014000000
                        setSqmile(val);
                    }
                    
                }
                 
            />
             </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <TextField  className={classes.root} label="Sq Meter" size="small" variant="outlined" fullWidth
           
            type="number"
            InputProps={{
              style: {
                  color: "#ffd24d",
                  borderColor: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}

            value={sqmet}
            onChange={(e) => {
                
                    var val = parseFloat(e.target.value);
                    var val1 = parseFloat(e.target.value);
                    setSqmet(val1);
                    val=val1/2590000
                    setSqmile(val);
                    val=val1*1550.003
                    setSqinch(val);
                    val=val1*10000
                    setSqcent(val);
                    val=val1*1.196
                    setSqyard(val);
                    val=val1*10.764
                    setSqfeet(val);
                    val=val1/10000
                    setHect(val);
                    val=val1/4046.856
                    setAcre(val);
                }
            }
             
          />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <TextField  className={classes.root} label="Sq Centimeter" size="small" variant="outlined" fullWidth
            type="number"
            InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}

            value={sqcent}
            onChange={(e) => {
                
                    var val = parseFloat(e.target.value);
                    var val1 = parseFloat(e.target.value);
                    setSqcent(val1);
                    val=val1/6.452
                    setSqinch(val);
                    val=val1/10000
                    setSqmet(val);
                    val=val1/8361.274
                    setSqyard(val);
                    val=val1/929.03
                    setSqfeet(val);
                    val=val1/100000000
                    setHect(val);
                    val=val1/40470000
                    setAcre(val);
                    val=val1/25900000000
                    setSqmile(val);
                }
            }
             
          />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <TextField  className={classes.root} label="Sq Yards" size="small" variant="outlined" fullWidth
            type="number"
            InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}

            value={sqyard}
            onChange={(e) => {
                
                    var val = parseFloat(e.target.value);
                    var val1 = parseFloat(e.target.value);
                    setSqyard(val1);
                    val=val1*1296
                    setSqinch(val);
                    val=val1/1.196
                    setSqmet(val);
                    val=val1*8361.274
                    setSqcent(val);
                    val=val1*9
                    setSqfeet(val);
                    val=val1/11959.9
                    setHect(val);
                    val=val1/4840
                    setAcre(val);
                    val=val1/3098000
                    setSqmile(val);
                }
            }
             
          />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <TextField  className={classes.root} label="Sq Feet" size="small"  variant="outlined" fullWidth
            type="number"
            InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}

            value={sqfeet}
            onChange={(e) => {
                
                    var val = parseFloat(e.target.value);
                    var val1 = parseFloat(e.target.value);
                    setSqfeet(val1);
                    val=val1*144
                    setSqinch(val);
                    val=val1/10.764
                    setSqmet(val);
                    val=val1*929.03
                    setSqcent(val);
                    val=val1/9
                    setSqyard(val);
                    val=val1/107639.104
                    setHect(val);
                    val=val1/43560
                    setAcre(val);
                    val=val1/27880000
                    setSqmile(val);
                }
            }
             
          />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <TextField  className={classes.root} label="Hectares" size="small"  variant="outlined" fullWidth
            type="number"
            InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}

            value={hect}
            onChange={(e) => {
                
                    var val = parseFloat(e.target.value);
                    var val1 = parseFloat(e.target.value);
                    setHect(val1);
                    val=val1*15500000
                    setSqinch(val);
                    val=val1*10000
                    setSqmet(val);
                    val=val1*100000000
                    setSqcent(val);
                    val=val1*11959.9
                    setSqyard(val);
                    val=val1*107639.104
                    setSqfeet(val);
                    val=val1*2.471
                    setAcre(val);
                    val=val1/258.999
                    setSqmile(val);
                }
            }
             
          />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <TextField  className={classes.root} label="Acres" size="small"  variant="outlined" fullWidth
            type="number"
            InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}

            value={acre}
            onChange={(e) => {
                
                    var val = parseFloat(e.target.value);
                    var val1 = parseFloat(e.target.value);
                    setAcre(val1);
                    val=val1*6273000
                    setSqinch(val);
                    val=val1*4046.856
                    setSqmet(val);
                    val=val1*40470000
                    setSqcent(val);
                    val=val1*4840
                    setSqyard(val);
                    val=val1*43560
                    setSqfeet(val);
                    val=val1*0.404686
                    setHect(val);
                    val=val1/640
                    setSqmile(val);
                }
            }
             
          />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <TextField  className={classes.root} label="Sq Mile" size="small"  variant="outlined" fullWidth
            type="number"
            InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}

            value={sqmile}
            onChange={(e) => {
                
                    var val = parseFloat(e.target.value);
                    var val1 = parseFloat(e.target.value);
                    setSqmile(val1);
                    val=val1*4014000000
                    setSqinch(val);
                    val=val1*2590000
                    setSqmet(val);
                    val=val1*25900000000
                    setSqcent(val);
                    val=val1*309800
                    setSqyard(val);
                    val=val1*27880000
                    setSqfeet(val);
                    val=val1*258.999
                    setHect(val);
                    val=val1*640
                    setAcre(val);
                }
            }
             
          />
          </Grid>
    </Grid></Container>);
}